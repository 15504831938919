<template>
	<Popup title="Login" :other-parts="[ForgotPasswordPopupPart, SignupPopupPart]">
		<form class="flex flex-col gap-4">
			<FormGroup
					v-model:parent_ref="formData.username"
					label="Username"
					placeholder="Username"
					autocomplete="username, email"
			/>
			<FormGroup
					v-model:parent_ref="formData.password"
					label="Password"
					placeholder="Password"
					type="password"
					autocomplete="current-password"
			/>
			<div class="bg-gray-200 w-full h-px"></div>
			<PopupsComponentsPopupButton
					text="Login"
					:action="login"
					:arrow="null"
			/>
		</form>
	</Popup>
</template>

<script setup>
import { usePopup } from "~/composables/usePopup";
import { notify } from "@kyvg/vue3-notification";
import ForgotPasswordPopupPart from '~/components/popups/parts/ForgotPasswordPopupPart.vue';
import SignupPopupPart from '~/components/popups/parts/SignupPopupPart.vue';
import { getUserData } from '~/composables/useUser.js';

// login

const formData = reactive({
	username: "",
	password: "",
});

const loading = ref(false);

const { query } = useRoute();
const redirect = query.redirect;

const { setPopup } = usePopup();
const user = useUser();

const login = async () => {
	if (loading.value) return;
	loading.value = true;

	try {
		const response = await $fetch("/api/user/login", {
			method: "POST",
			body: {
				username: formData.username,
				password: formData.password,
			},
			ignoreResponseError: true,
		});

		if (response.status) {
			notify({
				type: "success",
				title: "You have successfully logged in"
			})

			setPopup(null);

			const userData = await getUserData(response.session);
			if (userData) {
				user.value = userData;
			}
			setUser(userData);

			if (redirect) {
				window.location.href = redirect;
			}
		} else if (response.error) {
			notify({
				type: "error",
				title: response.error
			})
		} else {
			notify({
				type: "error",
				title: "Error occurred while logging in"
			})
		}
	} catch (e) {
		notify({
			type: "error",
			title: "Something went wrong"
		})
	} finally {
		loading.value = false;
	}
};
</script>